import { imageToBase64 } from '#utils/image-to-base-64/imabe-to-base-64';
import { Content, TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';

interface ProductCharacteristic {
    name: string;
    value: string;
}

interface ProformBillData {
    invoiceNo: string;
    customerName: string;
    customerRtn: string;
    customerAddress: string;
    customerContact: string;
    advisorName: string;
    date: string;
    observation: string;
    managerName: string;
    currencySymbol: string;
    companyName: string;
    products: {
        grossPriceInLetters: string;
        quantity: number;
        description: string;
        unitPrice: number;
        total: number;
        totalTax: number;
        finalTotal: number;
        totalToFinance: number;
        premiumAbonated: number;
        exoneratedAmount: number;
        exentAmount: number;
        discountAmount: number;
        location: string;
        gravedTax: number;
        creationDate: string;
        taxRate: number;
        characteristics: ProductCharacteristic[];
    }[];
}

export interface PrintProformBillParams {
    data: ProformBillData;
}

interface ProFormBillReturn {
    documentDefinition: TDocumentDefinitions;
    tableLayouts: any;
}

export const PdfProFormBillTableLayouts = {
    ownLayout: {
        // Configuración para eliminar todas las líneas (bordes)
        hLineWidth: function () {
            return 0; // Líneas horizontales invisibles
        },
        vLineWidth: function () {
            return 0; // Líneas verticales invisibles
        },
        hLineColor: function () {
            return 'transparent'; // Color transparente por seguridad
        },
        vLineColor: function () {
            return 'transparent'; // Color transparente por seguridad
        },
        // Configuración para eliminar el padding de las celdas
        paddingLeft: function () {
            return 0;
        },
        paddingRight: function () {
            return 0;
        },
        paddingTop: function () {
            return 0;
        },
        paddingBottom: function () {
            return 0;
        },
    },
    noMarginNoPaddingNoBorder: {
        hLineWidth: function () {
            return 0;
        },
        vLineWidth: function () {
            return 0;
        },
        hLineColor: function () {
            return 'transparent';
        },
        vLineColor: function () {
            return 'transparent';
        },
        paddingLeft: function () {
            return 0;
        },
        paddingRight: function () {
            return 0;
        },
        paddingTop: function () {
            return 0;
        },
        paddingBottom: function () {
            return 0;
        },
    },
};

export const proformBillDocument = async (
    options: PrintProformBillParams
): Promise<ProFormBillReturn> => {
    const { data } = options;

    const currencyFormatter = new Intl.NumberFormat('es-HN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    const formatNumberWithCurrency = (value: number) => {
        return `${options.data.currencySymbol} ${currencyFormatter.format(value)}`;
    };

    const getMonthName = (month: number) => {
        const dateFormater = new Intl.DateTimeFormat('es-HN', {
            month: 'long',
        });

        return dateFormater.format(new Date(2021, month));
    };

    const getDayName = (date: Date) => {
        const dateFormater = new Intl.DateTimeFormat('es-HN', {
            weekday: 'long',
        });

        return dateFormater.format(date);
    };

    let documentImages: TDocumentDefinitions['images'] = {};

    const baseContent: Content = [];

    const documentHeader: Content = [];

    const companyInfoTable: TDocumentDefinitions['content'] = [];

    const logoAndAdvisorTable: TDocumentDefinitions['content'] = [];

    const dateAndAdvisorStack: TDocumentDefinitions['content'] = [];

    dateAndAdvisorStack.push({
        marginTop: 10,
        alignment: 'right',
        stack: [
            {
                text: `Fecha: ${new Intl.DateTimeFormat('es-HN').format(
                    new Date(data.date)
                )}`,
                bold: true,
                alignment: 'right',
                fontSize: 10,
            },
            {
                text: `Asesor: ${data.advisorName}`,
                alignment: 'right',
                bold: true,

                fontSize: 10,
            },
        ],
    });

    const companyInfoStack: TDocumentDefinitions['content'] = [];

    companyInfoStack.push({
        alignment: 'center',
        stack: [
            {
                text: 'DISTRIBUIDORA PLATINO SA DE CV',
                alignment: 'center',
                bold: true,
                fontSize: 12,
            },
            {
                text: 'R.T.N. 05019021272375',
                alignment: 'center',
                marginTop: 5,
                fontSize: 10,
            },
            {
                text: 'Bulevar del Este, Salida a La Lima, contiguo a Maderera Noriega, San Pedro Sula',
                alignment: 'center',
                marginTop: 5,
                fontSize: 10,
            },
        ],
    });

    companyInfoTable.push({
        alignment: 'left',
        layout: 'noMarginNoPaddingNoBorder',
        table: {
            headerRows: 1,
            widths: ['*'],
            body: [[companyInfoStack]],
        },
        marginBottom: 7,
    });

    logoAndAdvisorTable.push({
        alignment: 'left',
        layout: 'noMarginNoPaddingNoBorder',
        table: {
            headerRows: 1,
            widths: [250, '*'],
            body: [
                [
                    {
                        alignment: 'left',
                        columns: [
                            {
                                image: 'motorsLog',
                                alignment: 'left',
                                fit: [150, 50],
                            },
                            // {
                            //     image: 'sinoTruckLogo',
                            //     alignment: 'left',
                            //     fit: [40, 40],
                            //     marginLeft: 10,
                            // },
                        ],
                    },
                    dateAndAdvisorStack,
                ],
            ],
        },
    });

    const withCodeColumns: TDocumentDefinitions['content'] = [];

    withCodeColumns.push(
        {
            text: 'A quien corresponda',
            alignment: 'left',
            bold: true,
            fontSize: 10,
        },
        {
            text: `FACTURA PROFORMA ${options.data.invoiceNo}`,
            alignment: 'right',
            bold: true,
            fontSize: 10,
        }
    );

    documentHeader.push({
        stack: [
            companyInfoTable,
            logoAndAdvisorTable,
            {
                marginTop: 7,
                columns: [...withCodeColumns],
            },
        ],
    });

    const colorDetailTable: TDocumentDefinitions['content'] = [];

    colorDetailTable.push({
        marginTop: 10,
        table: {
            widths: [150, '*'],
            body: [
                [
                    {
                        fillColor: '#c00000',
                        text: '',
                        margin: [0, 1],
                    },
                    {
                        fillColor: '#1B1E3E',
                        text: '',
                        margin: [0, 1],
                    },
                ],
            ],
        },
        layout: 'noBorders',
    });

    baseContent.push(documentHeader);

    baseContent.push(colorDetailTable);

    const clientInfoTable: TDocumentDefinitions['content'] = [];

    const clientInfoTableBody: TableCell[][] = [
        [
            {
                text: 'NOMBRE DEL CLIENTE:',
                bold: true,
                fontSize: 10,
            },
            {
                text: data.customerName,
                bold: true,
                fontSize: 10,
            },
        ],
        [
            {
                text: 'RTN:',
                bold: true,
                fontSize: 10,
            },
            {
                text: data.customerRtn,
                bold: true,
                fontSize: 10,
            },
        ],
        [
            {
                text: 'Dirección:',
                bold: true,
                fontSize: 10,
            },
            {
                text: data.customerAddress,
                bold: true,
                fontSize: 10,
            },
        ],
        [
            {
                text: 'Contacto:',
                bold: true,
                fontSize: 10,
            },
            {
                text: data.customerContact,
                bold: true,
                fontSize: 10,
            },
        ],
    ];

    clientInfoTable.push({
        marginTop: 10,
        table: {
            widths: [140, '*'],
            headerRows: 1,
            body: clientInfoTableBody,
        },
    });

    const allProductsTables: TDocumentDefinitions['content'] = [];

    data.products.forEach((product, index) => {
        const productDate = new Date(product.creationDate);
        const isLast = index === data.products.length - 1;

        const productInfoTableHeader: TableCell[] = [
            {
                text: 'CANTIDAD',
                bold: true,
                alignment: 'center',
                fillColor: '#1B1E3E',
                color: '#ffffff',
                fontSize: 9,
            },
            {
                text: 'DESCRIPCIÓN',
                bold: true,
                alignment: 'center',
                fillColor: '#1B1E3E',
                color: '#ffffff',
                fontSize: 9,
            },
            {
                text: 'PRECIO UNITARIO',
                bold: true,
                alignment: 'center',
                fillColor: '#1B1E3E',
                color: '#ffffff',
                fontSize: 9,
            },
            {
                text: 'TOTAL',
                bold: true,
                alignment: 'center',
                fillColor: '#1B1E3E',
                color: '#ffffff',
                fontSize: 9,
            },
        ];

        const productInfoTableBody: TableCell[] = [
            {
                text: product.quantity,
                alignment: 'center',
                fontSize: 9,
            },
            {
                layout: PdfProFormBillTableLayouts.ownLayout,
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                table: {
                    margin: [0, 0, 0, 0],
                    widths: [70, '*'],
                    body: [
                        [
                            {
                                margin: [4, 2],
                                text: product.description,
                                colSpan: 2,
                                fontSize: 9,
                            },
                            {
                                text: '',
                            },
                        ],
                        [
                            {
                                margin: [4, 0],
                                text: 'Descripción del vehículo',
                                colSpan: 2,
                                bold: true,
                                fillColor: '#1B1E3E',
                                color: '#ffffff',
                                fontSize: 9,
                            },
                            {
                                text: '',
                            },
                        ],
                        ...product.characteristics.map((characteristic) => [
                            {
                                text: `${characteristic.name}:`,
                                bold: true,
                                fontSize: 9,
                                margin: [4, 1],
                            },
                            {
                                text: characteristic.value,
                                fontSize: 9,
                                margin: [4, 1],
                            },
                        ]),
                    ],
                },
            },
            {
                colSpan: 2,
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            {
                                border: [false, false, true, false],
                                text: formatNumberWithCurrency(
                                    product.unitPrice
                                ),
                                alignment: 'center',
                                fontSize: 9,
                            },
                            {
                                border: [true, false, false, false],
                                text: formatNumberWithCurrency(product.total),
                                alignment: 'right',
                                fontSize: 9,
                            },
                        ],
                        [
                            {
                                border: [false, false, true, false],
                                text: `${product?.description?.substring(0, product?.description?.length / 2)}...`,
                                color: 'white',
                            },
                            {
                                border: [false, false, false, false],
                                text: '',
                                color: 'white',
                            },
                        ],
                        ...product.characteristics.map((c, i) => [
                            {
                                border: [false, false, true, false],
                                text: c.name,
                                color: 'white',
                            },
                            {
                                border: [false, false, false, false],
                                text: c.value,
                                color: 'white',
                            },
                        ]),
                        [
                            {
                                border: [false, true, true, true],
                                text: 'Importe Gravado',
                                alignment: 'center',
                                fontSize: 9,
                            },
                            {
                                border: [false, true, false, true],
                                text: formatNumberWithCurrency(
                                    product.totalTax
                                ),
                                alignment: 'right',
                                fontSize: 9,
                            },
                        ],
                        [
                            {
                                border: [false, false, true, true],
                                text: 'Importe Exonerado',
                                alignment: 'center',
                                fontSize: 9,
                            },
                            {
                                border: [false, true, false, true],
                                text: formatNumberWithCurrency(
                                    product.exoneratedAmount
                                ),
                                alignment: 'right',
                                fontSize: 9,
                            },
                        ],
                        [
                            {
                                border: [false, false, true, true],
                                text: 'Importe Exento',
                                alignment: 'center',
                                fontSize: 9,
                            },
                            {
                                border: [false, true, false, true],
                                text: formatNumberWithCurrency(
                                    product.exentAmount
                                ),
                                alignment: 'right',
                                fontSize: 9,
                            },
                        ],
                        [
                            {
                                border: [false, false, true, false],
                                text: 'Descuentos y Reb',
                                alignment: 'center',
                                fontSize: 9,
                            },
                            {
                                border: [false, true, false, false],
                                text: formatNumberWithCurrency(
                                    product.discountAmount
                                ),
                                alignment: 'right',
                                fontSize: 9,
                            },
                        ],
                    ],
                },
            },
            {
                text: ``,
            },
        ];

        const productTableFooterFirstLine: TableCell[] = [];

        productTableFooterFirstLine.push(
            {
                colSpan: 2,
                text: `Son: ${product.grossPriceInLetters}`,
                bold: true,
                margin: [10, 5],
            },
            {
                text: '',
            },
            {
                text: '',
                colSpan: 2,
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            {
                                border: [false, false, true, false],
                                text: `Gravados ISV 15%`,
                                alignment: 'center',
                                fontSize: 9,
                            },
                            {
                                border: [true, false, false, false],
                                text: formatNumberWithCurrency(
                                    product.gravedTax
                                ),
                                alignment: 'right',
                                fontSize: 9,
                            },
                        ],
                    ],
                },
            },
            {
                text: '',
            }
        );

        const productTableFooterSecondLine: TableCell[] = [];

        if (isLast) {
            productTableFooterSecondLine.push(
                {
                    text: '',
                    colSpan: 2,
                    table: {
                        widths: ['*', 110],
                        body: [
                            [
                                {
                                    border: [false, false, false, false],
                                    text: [
                                        { text: 'Observaciones: ', bold: true }, // Palabra en negrita
                                        { text: `${data.observation}` }, // Resto del texto normal
                                    ],
                                    alignment: 'left',
                                },
                                {
                                    border: [false, false, false, false],
                                    text: [
                                        {
                                            text: 'Tasa de cambio: ',
                                            bold: true,
                                        }, // Palabra en negrita
                                        { text: `${product.taxRate}` }, // Resto del texto normal
                                    ],
                                    alignment: 'left',
                                },
                            ],
                        ],
                    },
                },
                {
                    text: '',
                },
                {
                    text: '',
                    colSpan: 2,
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {
                                    border: [false, false, true, true],
                                    text: `Total`,
                                    alignment: 'center',
                                    fontSize: 9,
                                    fillColor: '#D8D8D8',
                                    bold: true,
                                },
                                {
                                    border: [true, false, false, true],
                                    text: formatNumberWithCurrency(
                                        product.finalTotal
                                    ),
                                    alignment: 'right',
                                    fontSize: 9,
                                    fillColor: '#D8D8D8',
                                    bold: true,
                                },
                            ],
                            [
                                {
                                    border: [false, false, true, true],
                                    text: `Prima / Abono 10%`,
                                    alignment: 'center',
                                    fontSize: 9,
                                    fillColor: '#D8D8D8',
                                    bold: true,
                                },
                                {
                                    border: [true, false, false, true],
                                    text: formatNumberWithCurrency(
                                        product.premiumAbonated
                                    ),
                                    alignment: 'right',
                                    fontSize: 9,
                                    fillColor: '#D8D8D8',
                                    bold: true,
                                },
                            ],
                            [
                                {
                                    border: [false, false, true, false],
                                    text: `Saldo a financiar`,
                                    alignment: 'center',
                                    fontSize: 9,
                                    fillColor: '#D8D8D8',
                                    bold: true,
                                },
                                {
                                    border: [true, false, false, false],
                                    text: formatNumberWithCurrency(
                                        product.totalToFinance
                                    ),
                                    alignment: 'right',
                                    fontSize: 9,
                                    fillColor: '#D8D8D8',
                                    bold: true,
                                },
                            ],
                        ],
                    },
                },
                {
                    text: '',
                }
            );
        }

        const productTableFooterThirdLine: TableCell[] = [];

        if (isLast) {
            productTableFooterThirdLine.push(
                {
                    margin: [0, 10],
                    colSpan: 4,
                    stack: [
                        [
                            {
                                text: `Y para los fines estime conveniente se extiende la presente factura en ${product.location} el día ${getDayName(productDate)} ${productDate.getDate()} de ${getMonthName(productDate.getMonth())} de ${productDate.getFullYear()}`,
                                bold: true,
                                alignment: 'center',
                            },
                        ],
                        [
                            {
                                marginTop: 0,
                                layout: PdfProFormBillTableLayouts.ownLayout,
                                table: {
                                    widths: [400, '*'],
                                    body: [
                                        [
                                            {
                                                stack: [
                                                    {
                                                        text: '______________________________',
                                                        alignment: 'center',
                                                        margin: [0, 50, 0, 0], // Alinea más cerca de la imagen
                                                    },
                                                    {
                                                        text: data.managerName,
                                                        alignment: 'center',
                                                        bold: true,
                                                    },
                                                    {
                                                        text: `Gerente Comercial ${data.companyName}`,
                                                        alignment: 'center',
                                                        bold: true,
                                                    },
                                                    {
                                                        text: '**Estos Precios no son Válidos sin la firma del Gerente Comercial',
                                                        alignment: 'center',
                                                    },
                                                ],
                                            },

                                            {
                                                text: '',
                                            },
                                        ],
                                    ],
                                },
                            },
                        ],
                    ],
                },
                {},
                {},
                {}
            );
        }

        const productInfoTable: TDocumentDefinitions['content'] = [];

        productInfoTable.push({
            layout: {
                paddingLeft: function (i, node) {
                    return 0;
                },
                paddingRight: function (i, node) {
                    return 0;
                },
                paddingTop: function (i, node) {
                    return 0;
                },
                paddingBottom: function (i, node) {
                    return 0;
                },
            },
            marginTop: 10,
            table: {
                widths: [60, 300, '*', '*'],
                headerRows: 1,
                body: [
                    productInfoTableHeader,
                    productInfoTableBody,
                    productTableFooterFirstLine,
                    isLast ? productTableFooterSecondLine : null,
                    isLast ? productTableFooterThirdLine : null,
                ].filter((x) => x !== null),
            },
        });

        allProductsTables.push(productInfoTable);
    });

    baseContent.push(clientInfoTable);

    baseContent.push(allProductsTables);

    documentImages = {
        ...documentImages,
        motorsLog: await imageToBase64(
            'assets/corporate_logos/platino_motors_logo.png'
        ),
        sinoTruckLogo: await imageToBase64(
            'assets/corporate_logos/sinoTruckLogo.png'
        ),
    };

    return {
        documentDefinition: {
            pageSize: 'LETTER',
            pageMargins: [10, 20, 10, 25],
            content: baseContent,
            images: {
                ...documentImages,
                facebookIcon: await imageToBase64('assets/icons/facebook.png'),
                instagramIcon: await imageToBase64(
                    'assets/icons/instagram.png'
                ),
                sinoTruckOneLine: await imageToBase64(
                    'assets/corporate_logos/sinotruck_one_line.png'
                ),
                logo_sany_white: await imageToBase64(
                    'assets/corporate_logos/logo_sany_white.png'
                ),
            },
            defaultStyle: {
                fontSize: 8,
            },
            footer: (currentPage, pageCount) => {
                return [
                    {
                        margin: [10, -20],
                        columns: [
                            {
                                table: {
                                    widths: [150, '*'],
                                    body: [
                                        [
                                            {
                                                text: '',
                                                fillColor: '#1B1E3E',
                                            },
                                            {
                                                alignment: 'right',
                                                color: 'white',
                                                fillColor: '#c00000',
                                                columns: [
                                                    {
                                                        text: 'Distribuidores autorizados',
                                                        fontSize: 10,
                                                        color: 'white',
                                                        marginTop: 6,
                                                        marginRight: 5,
                                                    },

                                                    {
                                                        image: 'logo_sany_white',
                                                        width: 90,
                                                        height: 21,
                                                        marginTop: 2,
                                                    },
                                                    {
                                                        image: 'sinoTruckOneLine',
                                                        width: 130,
                                                        height: 25,
                                                        marginTop: -2,
                                                    },
                                                ],
                                            },
                                        ],
                                    ],
                                },
                                layout: 'noBorders',
                            },
                        ],
                    },
                ];
            },
        },
        tableLayouts: PdfProFormBillTableLayouts,
    };
};
