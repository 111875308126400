import { Injectable } from '@angular/core';
import { TCreatedPdf } from 'pdfmake/build/pdfmake';
import {
    PrintProformBillParams,
    proformBillDocument,
} from './proform-bill.format';

type PDFMake = typeof import('pdfmake/build/pdfmake');

@Injectable({
    providedIn: 'root',
})
export class PrintProformaInvoiceService {
    constructor() {}
    private pdfMake: PDFMake;
    private fonts: { [file: string]: string };

    private async loadPDFMaker() {
        if (!this.pdfMake) {
            try {
                const pdfMakeModule = await import('pdfmake/build/pdfmake');
                const pdfFontsModule = await import('pdfmake/build/vfs_fonts');

                this.pdfMake = pdfMakeModule.default || pdfMakeModule;
                this.pdfMake.vfs = pdfFontsModule?.pdfMake?.vfs;
            } catch (error) {
                console.error('Failed to load pdfMake or fonts:', error);
                throw error;
            }
        }
    }

    async getDocument(params: PrintProformBillParams) {
        if (!this.pdfMake) {
            try {
                await this.loadPDFMaker();
            } catch (error) {
                console.error('Failed to load pdf maker lib');
            }
        }

        const { documentDefinition, tableLayouts } =
            await proformBillDocument(params);

        const pdf = this.pdfMake.createPdf(
            {
                info: {
                    ...documentDefinition?.info,
                    title: `${this.fileName} ${params.data.invoiceNo} - ${params.data.customerName}`,
                },
                ...documentDefinition,
            },
            tableLayouts
        );

        return pdf;
    }

    async printDocument(params: PrintProformBillParams) {
        const pdf = await this.getDocument(params);

        pdf.open();
    }

    async getDocumentBase64(params: PrintProformBillParams): Promise<{
        base64: string;
        fileName: string;
        createdPdf: TCreatedPdf;
    }> {
        const pdf = await this.getDocument(params);

        return new Promise((resolve, reject) => {
            pdf.getBase64((data) => {
                resolve({
                    base64: data,
                    fileName: `${this.fileName} ${params.data.invoiceNo} - ${params.data.customerName}`,
                    createdPdf: pdf,
                });
            });
        });
    }

    get fileName() {
        return `Factura Proforma `;
    }
}
